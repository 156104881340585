import React from 'react'
import PropTypes from 'prop-types'
import Layout from '@organisms/layout'
import SEO from '@atoms/seo'

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="404: Not found" location={location} />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)
NotFoundPage.propTypes = {
  location: PropTypes.any
}

export default NotFoundPage
